.business-payment-body {
  margin-top: 100px;
}
.business-payment-body-1 {
  position: relative;
  z-index: 999;
  padding: 50px 200px;
}
.business-payment-method-icon {
  background: #fff;
  padding: 3px;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  text-align: center;
}
.business-payment-method-icon svg {
  margin-top: 11px;
  margin-left: 4px;
  width: 40px;
}
.business-payment-method {
  display: flex;
  margin-top: 30px;
  width: 500px;
}
.business-payment-method div:nth-child(2) {
  margin-left: 10px;
  height: 175px;
}
.business-payment-body-1 h1 {
  color: #4abceb;
  font-family: Söhne;
  font-size: 38px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
}
.business-payment-method h6 {
  color: #fff;
  font-family: Söhne;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.business-payment-method p {
  color: #c8d0db;
  font-family: Söhne;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.business-payment-body-1 .row {
  margin-top: 50px;
}
.business-payment-body-2 h1 {
  color: #fff !important;
  text-align: center;
  font-family: Söhne;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  padding: 0 120px;
}
.business-payment-table-1 td {
  color: #fff !important;
  font-family: Söhne;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.business-payment-table-1 .payment-table-row h6 {
  color: #fff !important;
  font-family: Söhne;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.business-payment-table-1 .payment-table-row p {
  color: #c8d0db !important;
  font-family: Söhne;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.business-payment-table-1 .payment-table-row-2 div {
  color: #fff !important;
  text-align: center;
  font-family: Söhne;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.business-payment-reward {
  display: block !important;
}
.business-payment-reward div:nth-child(2) {
  margin-left: 0;
}
.business-payment-reward h6 {
  padding: 10px 0 0 0;
}
.paid-instantly {
  padding: 100px 200px;
}
.paid-instantly h1 {
  text-align: center;
  color: #0a2540;
  font-family: Söhne;
  font-size: 38px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.paid-instantly p {
  color: #425466;
  text-align: center;
  font-family: Söhne;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding: 30px 150px;
}
.paid-instantly-row {
  display: flex;
  justify-content: center;
  margin: auto;
}
.paid-instantly-row img {
  display: flex;
  justify-content: center;
  margin: auto;
}
.paycount {
  margin-top: 50px !important;
  margin-left: 45px;
}
.paycount1 {
  margin-top: 85px !important;
  margin-left: 45px;
}
.paid-instantly-row p {
  padding: 0;
  text-align: left;
  color: #425466;
  font-family: Söhne;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-left: 45px;
}
.paid-instantly-row h6 {
  color: #0a2540;
  font-family: Söhne;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-left: 45px;
  padding: 10px 0 0 0;
}
.paycount1-1 {
  margin-top: 50px !important;
}

.paid-invoice {
  background: #F6F9FC;
}
.paid-invoice img {
  display: flex;
  justify-content: center;
  margin: auto;
}

.paid-instantly {
  padding: 100px 200px !important;
}

.paid-instantly-2 {
  padding: 0 !important;
}

.horizontal-line {
  border-top: 2px solid rgba(216, 216, 216, 1);
  ;
    margin-top: 20px;
    margin-bottom: 20px;
    position: absolute;
    margin-top: 251px;
    width: 1005px;
}
.horizontal-line-2 {
  border-top: 2px solid rgba(216, 216, 216, 1);
    margin-top: 20px;
    margin-bottom: 20px;
    position: absolute;
    margin-top: 58px;
    width: 1005px;
}
