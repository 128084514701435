.dts-hero {
  padding: 70px 200px;
}
.duo-list-container p {
  width: fit-content;
}
.dts-hero button {
  padding: 8px 15px;
  border: none;
  border-radius: 8px;
  background: #ffc000;
  color: #101010;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  margin-top: 30px;
  line-height: 145%; /* 23.2px */
}


.dts-hero img {
  display: flex;
  justify-content: end;
  margin: auto;
  margin-right: 0;
}

.dts-body {
  padding: 70px 200px;
  background: #f6f9fc;
}
.dts-body2 {
  padding: 70px 300px;
}
.dts-body2 h6 {
  color: #101010;
  font-family: Söhne;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.dts-body2 p {
  color: #425466;
  font-family: Söhne;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  width: 400px;
}
.dts-body2 button {
  border-radius: 8px;
  background: #000;
  border: none;
  color: #fff;
  text-align: center;

  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  padding: 10px 15px;
}
.dts-body2 .col:nth-child(2) {
  margin-top: 100px;
}
.dts-hero h1 {
  color: #101010;
  font-family: Söhne2;
  font-size: 56px;
  font-style: normal;
  font-weight: 300;
  line-height: 68px; 
}
.dts-hero h1 span {
  color: #101010;
  font-family: Söhne3;
  font-size: 56px;
  font-style: normal;
  font-weight: 500;
  line-height: 68px;
}

.dts-frame {
  border: 1px solid #9DB1C5;
  border-radius: 15px;
 
}
.dts-frame-img img {
  position: absolute;
  margin-top: -30px;
  margin-left: 15px;
}
.dts-frame-header h6 {
  font-size: 18px;
  font-family: Söhne3;
  font-weight: 500;
  padding: 40px 20px 0 20px;
  color: #101010;
}

.dts-list {
  width: 500px;
  height: 430px;
  max-width: 100%;
  padding: 0 13px;
  max-height: 100%;
}
.dts-list p {
  width: 100%;
  font-weight: 400;
  font-size: 16px;
}
.dts-frame-1 {
  margin-left: 30px;
}