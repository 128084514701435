.face-auth-hero {
    padding: 70px 0 70px 200px;
}

.face-auth-hero h1 {
    color: #0B74E6;
    font-size: 76px;
    line-height: 96%;
    font-weight: 600;
    width: 496px;
}
.face-auth-img1 {
    padding: 20px 0;
}
.face-auth-img2 {
    padding: 20px 0;
}

.face-auth-hero button {
    padding: 10px 15px;
    border: none;
    background: #37CC00;
    border-radius: 8px;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
}

.face-auth-body {
    text-align: center;
    padding: 70px 200px;
    background: #F6F9FC;
    
}
.face-auth-body .row {
    margin-top: 100px;
}
.verified-part-text {
    text-align: left;
}

.faceauthrow {
    display: flex;
    margin-top: 20px;
}
.faceauthrow1 {
    display: flex;
}
.aaa p {
    margin: 0;
}
.aaa {
    margin-left: 5px;
}
.faceauth {
    margin-top: 0;
}