.store-hero {
  padding: 70px 0 70px 200px;
}

.store-hero2 img {
  display: flex;
  justify-content: end;
  margin: auto;
  margin-right: 0;
}

.store-hero h1 {
  color: #20336b;
  font-family: Söhne2;
  font-size: 56px;
  font-style: normal;
  font-weight: 300;
  line-height: 68px;
  width: 591px;
}

.store-hero p {
  color: #20336b;
  font-family: Söhne;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  padding: 10px 0;
  width: 515px;
}

@media screen and (max-width: 768px) {
  .store-hero h1 {
    width: 100%;
  }
  .store-hero p {
    width: 100%;
  }
}

.store-hero p:nth-child(1) {
  color: #20336b;
  font-family: Söhne;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding: 0;
}
.store-hero h1 span {
  color: #20336b;
  font-family: Söhne;
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
  line-height: 68px;
}
.s-icon {
  background: #fff;
  padding: 3px;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  text-align: center;
}
.s-icon svg {
  margin-top: 11px;
  margin-left: 4px;
  width: 40px;
}