.auth-app {
  padding: 70px 100px 70px 200px;
}

.auth-app h1 {
  color: #0b74e6;
  font-family: Söhne;
  font-size: 76px;
  font-style: normal;
  font-weight: 600;
  line-height: 90px;
  width: 480px;
}
.auth-app h1 span {
  color: #37cc00;
  font-family: Söhne;
  font-size: 76px;
  font-style: normal;
  font-weight: 600;
  line-height: 90px;
}
.auth-app p {
  color: #425466;
  font-family: Söhne;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  width: 451px;
}

.auth-app-img {
  padding: 20px 0 30px 0;
}

.auth-app button {
  padding: 10px 15px;
}

.auth-app-img-2 img {
  display: flex;
  justify-content: end;
  margin: auto;
  margin-right: 0;
}

.auth-app-body-1 {
  padding: 70px 100px 70px 200px;
  background: #f6f9fc;
}
.auth-app-body-1 h1 {
  color: #0b74e6;
  text-align: center;
  font-family: Söhne;
  font-size: 38px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.auth-app-body-1-inner {
  margin-top: 100px;
}
.auth-app-img-2 {
  display: flex;
  justify-content: center;
  margin: auto;
  margin-right: 0;
}

.auth-app-body-2-2 h1 {
  text-align: center;
  color: #101010;
  font-weight: 600;
  padding: 100px 200px 0 200px;
}
.auth-app-body-2-2 h1 span {
  color: #37CC00;
}
.auth-app-body-2 {
  padding: 70px 0 70px 200px;
}

.auth-app-body-2-img img {
  display: flex;
  justify-content: end;
  margin: auto;
  margin-right: 0;
  margin-top: 50px;
}
.auth-app-body-2 .row {
  margin-top: 50px;
}

.veri-part {
  width: 340px;
}

.veri-part-2 {
  width: 450px;
}
.veri-part-2 p {
  font-weight: 700;
  padding-top: 10px;
  font-family: Söhne;
  font-size: 18px;
}
.vf {
  display: flex;
}
.vf img {
  margin-top: -30px;
  margin-right: 13px
}
.vf p{
  margin-bottom: 0 !important;
}

.verified-part-text-text {
  display: flex;
  margin-top: 30px;
}
.verified-part-text-text p {
  font-family: Söhne3;
  font-weight: 500;
}

.verified-part-text-text img {
  margin-top: 10px;
  margin-right: 15px;
}

.verified-part-text-text span {
  width: 340px !important;
}

.fa span {
  width: 434px !important;
}