.inventory {
  padding: 70px 200px;
}
.inventory .col h1 {
  color: #20336b;
  font-family: Söhne2;
  font-size: 56px;
  font-style: normal;
  font-weight: 300;
  line-height: 68px;
  width: 565px;
}
.inventory1 {
    padding: 20px 0;
}
.inventory .col h1 span {
  color: #20336b;
  font-family: Söhne;
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
  line-height: 68px; 
}
.inventory .col ul {
  margin: 0 0 0 20px;
  padding: 0;
}
.inventory .col ul li {
  color: #20336b;
  font-family: Söhne;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  padding: 10px 0;
}

.inventory-hero2 img {
    display: flex;
    justify-content: end;
    margin: auto;
}