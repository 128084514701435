.cip-hero {
  padding: 70px 200px;
}
.cip-hero h1 {
  color: #4d148c;
  font-family: Söhne;
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
  line-height: 68px;
}
.cip-hero p {
  color: #4d148c;
  font-family: Söhne;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  padding: 20px 0;
}

.cip-hero-img {
  display: flex;
  justify-content: center;
  margin: auto;
  margin-right: 0;
}
.cip-hero button {
  border: none;
  border-radius: 8px;
  background: #ff5900;
  color: #fff;
  text-align: center;
  padding: 10px 15px;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 145%;
  margin-top: 20px;
}

.cip-hero-iamge {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
}

.cip-body {
  padding: 100px 200px;
  background: #f8f6fc;
}

.prepaid-card h6 {
  color: #4d148c;
  font-family: Söhne;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 145%;
  padding-top: 5px;
}
.prepaid-card p {
  color: #4d148c;
  text-align: center;
  font-family: Söhne;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.prepaid-card-header {
  text-align: center;
  padding: 30px 230px;
}

.prepaid-card {
  border-radius: 8px;
  border: 1px solid #4d148c;
  background: #fff;
  margin-top: 50px;
  padding: 30px 50px;
}

.cip-cards {
  display: flex;
  justify-content: space-between;
}

.cip-card p {
  text-align: left;
  width: 434px;
}

.cip-card {
  height: 200px;
}
.why-moneylink-body {
  border: none !important;
}

.why-moneylink {
  padding: 70px 200px;
}

.why-moneylink h1 {
  color: #4d148c;
  text-align: center;
  font-family: Söhne;
  font-size: 38px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.cip-hero-card h6 {
  color: #4D148C;
  font-weight: 600;
  font-size: 18px;
  font-family: Söhne;
}
.cip-hero-card {
  border: 1px solid #4D148C;
  background: #F8F6FC;
  padding: 20px;
  border-radius: 10px;
  height: 250px;
}
.cip-hero-cards {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.cip-hero-card-body {
  width: 350px;
  max-width: 100%;
  margin-right: 20px;
  
}

.cip-card-text {
  display: flex;
}
.cip-card-dot {
  width: 6px;
  height: 6px;
  background: #4D148C;
  margin-top: 12px;
  margin-right: 10px;
}

.cip-card-text p {
  padding: 0 !important;
}
.text-orange {
  color: #FF5900 !important;
  font-size: 16px !important;
  line-height: 24px !important;
}
.cip-card-text p {
  font-size: 16px !important;
  line-height: 24px !important;
}


.card-type {
  border: 1px solid #4D148C;
  background: #FFFFFF;
  border-radius: 10px;
  padding: 20px;
  width: 330px;
  max-width: 100%;
  height: 400px;
}

.card-type h6 {
  color: #4D148C;
  font-weight: 600;
  font-size: 16px;
  padding: 10px 0;
  font-family: Söhne;
}
.card-type p {
  color: #4D148C;
  font-weight: 400;
  font-size: 16px;
}

.card-types {
  display: flex;
  justify-content: space-between;
}
.credit-feature {
  display: flex;
  padding-top: 10px;
}
.card-types div:nth-child(1) {
  margin-right: 10px;
}

.credit-feature span {
  color: #4D148C;
  font-size: 16px;
  font-weight: 500;
}

.cip-body-1 h1 {
  text-align: center;
  font-size: 38px;
  color: #4D148C;
  font-weight: 600;
  margin-bottom: 50px;
}