.duo-body {
  background: #f6f9fc;
  padding: 70px 200px;
}
.duo-body h1 {
  color: #101010;
  text-align: center;
  font-family: Söhne3;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 925px;
  display: flex;
  justify-content: center;
  margin: auto;
}
.duo-list {
  display: flex;
}
.duo-list-box {
  width: 6px;
  height: 6px;
  flex-shrink: 0;
  background: #425466;
  margin-top: 8px;
  margin-right: 10px;
  margin-left: 7px;
}
.duo-home-body h6 {
  color: #101010;
  padding: 5px;
  font-family: Söhne3;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.duo-list p {
  color: #425466;
  font-family: Söhne;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  width: 735px;
}
.duo-home-body {
  display: grid;
  justify-content: center;
  margin: auto;
}
.duo-list-container {
  margin-top: 30px;
  width: 756px;
}
.duo-list-container p {
  width: 741px;
}
.duo-body2 {
  padding: 70px 200px;
}
.duo-body2 h6 {
  color: #101010;
  font-family: Söhne;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.duo-body2 h1 {
  text-align: center;
  color: #101010;
  font-family: Söhne;
  font-size: 38px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.duo-body2 button {
  border-radius: 8px;
  background: #000;
  padding: 8px 15px;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.duo-body-2 {
  display: flex;
  margin-top: 30px;
}
.duo-body-3 {
  display: flex;
}
.duo-body-3 h6 {
  color: #425466;
  font-family: Söhne;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.duo-body-3 p {
  color: #425466;
  font-family: Söhne;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.duo-body-2 div:nth-child(2) {
  margin-top: 70px;
  margin-left: 50px;
  width: 400px;
}
.duo-body2 {
  display: grid;
  justify-content: center;
  margin: auto;
}

.duo-body3 h1 {
  color: #101010;
  text-align: center;
  font-family: Söhne;
  font-size: 38px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.duo-body3 {
  background: #f6f9fc;
  padding: 70px 200px;
}

.duo-body-3 button {
  border-radius: 8px;
  background: #ffc000;
  padding: 8px 15px;
  border: none;
  color: #101010;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.duo-body-3 div:nth-child(2) {
  margin-top: 50px;
  margin-left: 50px;
}
.duo-body-3 {
  margin-top: 50px;
}

.duo-img {
  width: 463px;
  height: 254px;
  top: 0;
  left: 0;
  z-index: 1;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .duo-img {
    width: 100%;
    height: 100%;
  }


  .duo-overview-text h1 span {
    font-family: Söhne3 !important;
  }

}