.supplychain-page {
  padding: 70px 0 70px 200px !important;
}

.supplychain-page-1 {
  width: 564px !important;
}
.supplychain-page-1 h2 {
  color: #20336b;
  font-family: Söhne;
  font-size: 56px !important;
  font-style: normal;
  font-weight: 600;
  line-height: 68px !important;
  width: 564px !important;
}
.supplychain-page-1 h3 {
  color: #20336b;
  font-family: Söhne;
  font-size: 40px !important;
  font-style: normal;
  font-weight: 300;
  line-height: 68px;
}
.supplychain-page-2 {
  display: flex;
  justify-content: end;
  margin: auto;
  margin-top: 0 !important;
}

.supplychain-page-2 img {
  display: flex;
  justify-content: end;
  margin: auto;
}

.supply-body {
  background: #f6f9fc;
  padding: 70px 200px;
}
.supply-body h1 {
  color: #20336b;
  text-align: center;
  font-family: Söhne3;
  font-size: 38px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0 70px;
}

.supply-body h1 span {
  color: #00b0f0;
  font-family: Söhne3;
  font-size: 38px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.square-point {
  width: 6px;
  height: 6px;
  flex-shrink: 0;
  background: #0a2540;
}
.sup-point {
  display: flex;
}
.square-point {
  margin-top: 7px;
  margin-right: 10px;
}
.capabilities h6 {
  color: #0a2540;
  font-family: Söhne;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  padding-top: 10px;
}
.sup-point p {
  color: #0a2540;
  font-family: Söhne;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.capabilities {
    margin-top: 30px;
}
.phone1 {
    display: grid;
    justify-content: center;
    margin: auto;
    margin-top: 100px;
    padding-left: 100px;
}
.phone2 {
    margin-top: 300px;
}