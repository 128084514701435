.co-img img {
    width: 36%;
    display: flex;
    justify-content: end;
    margin: auto;
    margin-top: 30px;
    position: absolute;
    margin-left: 100px;
}
.customer-onboarding-text {
    width: 646px;
    max-width: 100%;
}