.merchant-hero {
  padding: 70px 200px;
}

.pos-img img {
  display: flex;
  justify-content: center;
  margin: auto;
  margin-right: 0;
}
.merchant-hero h1 {
  color: #4d148c;
  font-family: Söhne;
  font-size: 58px;
  font-style: normal;
  font-weight: 300;
  line-height: 68px;
  width: 644px;
}

.merchant-hero p {
  color: #4d148c;
  font-family: Söhne;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding: 10px 0;
  width: 519px;
}
.merchant-hero button {
  border-radius: 8px;
  background: #ff5900;
  padding: 10px 15px;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 145%;
  margin-top: 30px;
  border: none;
}

.merchant-hero h1 span {
  color: #4d148c;
  font-family: Söhne;
  font-size: 58px;
  font-style: normal;
  font-weight: 600;
  line-height: 68px;
}


.merchant-body {
    background: #F8F6FC;
}

.merchant-card {
    background: none;
}