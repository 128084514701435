.nav-link .navbar-nav .nav-item .nav-link.active {
    color: #00baf2 ;
    font-weight: bold;
  }


  .second-navbar .nav-link a {
    color: #6e6e6e ;
    font-family: "Helvetica Neue Regular";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 10px 20px;
  }
  
  .second-navbar .nav-link button:nth-child(1) {
    border: none;
    border-radius: 24px;
    background: #4abceb;
    color: var(--Shade-White, #fff);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 145%;
    /* padding: 8px 12px; */
  }