.company-hero {
  background-image: url("../../../assets/images/companybg.svg");
  background-size: cover;
  background-position: center;
  object-fit: cover;
  margin-top: 10px;
  padding: 70px 200px;
}
.money-trans {
  display: flex;
}
.money-trans-1 {
  border: 1px solid #e3d7d7;
  height: 52px;
  width: fit-content;
  padding: 3px;
  border-radius: 5px;
}
.money-trans-1 p {
  line-height: 5px;
  font-size: 22px;
  font-weight: 500;
  color: #fff;
}
.money-trans-2 p {
  line-height: 5px;
  font-size: 22px;
  font-weight: 500;
  color: #4abceb;
  margin-top: 11px;
  margin-left: 4px;
}
.money-trans-2 h6 {
  font-size: 12px;
  font-weight: 500;
  color: #4abceb;
  line-height: 5px;
  margin-left: 4px;
  margin-top: -2px;
}
.money-trans-1 p:nth-child(1) {
  margin-top: 7px;
}
.money-trans-1 p:nth-child(2) {
  margin-left: 15px;
}
.company-hero-first-part {
  display: flex;
}
.company-hero-1 h1 {
  width: 533px;
  max-width: 100%;
  color: #fff;
  font-size: 56px;
  font-weight: 600;
  margin-top: 20px;
  line-height: 68px;
}
.company-hero-2 img {
  position: absolute;
  margin-top: -150px;
  margin-left: -100px;
}
.company-hero-second-part {
  margin-top: 200px;
}
.service p {
  text-align: center;
  font-size: 13px;
  line-height: 17px;
  color: #fff;
  padding: 20px 0;
  font-family: Söhne;
}
.service {
  width: 170px;
}
.service-icon img {
  display: flex;
  justify-content: center;
  margin: auto;
}
.service-box {
  border: 1px solid #4abceb;
  border-radius: 10px;
  padding: 10px;
}
div.service-box-box {
  width: 5px;
  height: 5px;
  background: #fff;
}
.service-box-text p {
  text-align: left;
  padding: 0;
  margin: -6px 0 0 0;
  margin-left: 10px;
  font-size: 13px;
  font-family: Söhne;
  line-height: 17px;
  height: 40px;
  width: 130px;
}
.service-text {
  display: flex;
  margin-top: 10px;
}
.company-hero-second-part {
  display: flex;
  justify-content: space-between;
}

.company-body {
  padding: 100px 200px;
}

.vission {
  display: flex;
}

.mission {
  display: flex;
  margin-top: 100px;
}
.mission-img {
  display: flex;
  justify-content: end;
  margin: auto;
  margin-right: 0;
}
.vission-text {
  width: 450px;
  max-width: 100%;
  display: flex;
  justify-content: end;
  margin: auto;
  margin-right: 0;
}
.mission-text {
  width: 450px;
  max-width: 100%;
  display: flex;
  justify-content: end;
  margin: auto;
  margin-left: 0;
}
.vission-text h1 {
  font-size: 38px;
  font-weight: 500;
  font-family: Söhne3;
}
.mission-text h1 {
  font-size: 38px;
  font-weight: 500;
  font-family: Söhne3;
}
.mission-text p {
  font-size: 18px;
  font-weight: 400;
  font-family: Söhne;
  color: #425466;
}
.vission-text p {
  font-size: 18px;
  font-weight: 400;
  font-family: Söhne;
  color: #425466;
}

.montra-m p {
  color: #667185;
  line-height: 20px;
  font-size: 13px;
}
.montra-mm {
  height: 250px;
}
.montra-mmm {
  background: #edf8fd;
  border: none !important;
  box-shadow: 0 4px 8px rgba(88, 92, 95, 0.16);
  position: relative;
  margin-top: 28px;
}
.montra-mmm:hover {
  border: none;
  background: #20336b;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: #fff !important;
}
.montra-mmm:hover::before {
  background: #20336b;
}

.montra-mmm::before {
  content: "";
  position: absolute;
  top: -28px;
  left: 50%;
  transform: translateX(-50%);
  height: 10%;
  width: 1px;
  background-color: #d3d3d3;
}

.service-box-hero::before {
  content: "";
  position: absolute;
  top: -28px;
  left: 50%;
  transform: translateX(-50%);
  height: 28px;
  width: 1px;
  background-color: #4abceb;
}
.service-box-hero {
  position: relative;
  z-index: 999;
}
.service-m:hover h6 {
  color: #fff !important;
}
.service-m:hover p {
  color: #fff !important;
}
.montra-m h6 {
  font-size: 15px;
  color: #000;
  text-align: center;
  font-weight: 500;
  width: 103px;
  max-width: 100%;
  margin-bottom: 10px !important;
  font-family: Söhne3;
  display: flex;
  justify-content: center;
  margin: auto;
}
.montra-m p {
  color: #667185;
  font-size: 13px;
  font-weight: 400;
  font-family: Söhne;
  display: flex;
  justify-content: center;
  margin: auto;
  text-align: center;
}
.service-m {
  width: 153px;
}
.montra h1 {
  text-align: center;
  padding: 100px 0 50px 0;
  font-weight: 600;
  font-size: 38px;
}
.montra-meaning {
  display: flex;
  justify-content: space-between;
}
.logo-carousel {
  margin: 50px 0 50px 0;
  padding: 50px 200px;
  background: #fafafa;
}

.logo-item {
  display: flex;
  justify-content: center;
  align-items: center;
}
.sponsors h1 {
  font-size: 38px;
  font-weight: 600;
  text-align: center;
  font-family: Söhne;
}
.logo-item img {
  max-width: 100%;
  max-height: 100%;
}

.service-icon h4 {
  color: #D3D3D3;
  border: 1px solid #d3d3d3;
  font-family: "Texturina", serif;
  font-weight: 900;
  text-align: center;
  padding: 5px;
  border-radius: 50%;
  width: 46px;
  height: 46px;
  display: flex;
  justify-content: center;
  margin: auto;
}
.service-m:hover h4 {
  color: #20336B !important;
  border: 1px solid #20336B;
}
