.home-container {
  width: 100%;
  padding-inline-start: 200px;
  padding-inline-end: 40px;
  padding-block: 100px;
}

@media screen and (max-width: 1024px) {
  .home-container {
    padding-inline-start: 20px;
    padding-inline-end: 20px;
    padding-block: 70px;
  }
  
}

.login-box {
  width: 100%;
  border-radius: 40px;
  border: 1px solid #bfbfbf;
  position: relative;
  padding-inline-start: 50px;
  padding-inline-end: 80px;
  padding-block: 50px;
  display: flex;
  align-items: center;
}

.login-img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -30px;
  margin: auto 0;
}

.ani-step {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.ani-step-2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
}
.ani-step-3 {
  margin-left: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
}
.ani-step-4 {
  margin-left: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
}

.ani-step svg {
  margin-left: 30px;
}

.ani-step-1-box {
  background-color: #4abceb;
  border-radius: 20px;
  color: #fff;
  padding: 20px;
  max-width: 100px;
  text-wrap: wrap;
  text-align: center;
  position: relative;
  font-size: 10px;
}
.ani-step-1-box label {
  position: absolute;
  bottom: -40px;
  right: 0;
  left: -110px;
  margin: auto 0;
  color: #4abceb;
  font-weight: 600;
}

.connect-2 {
  position: absolute;
  top: 0;
  bottom: 83px;
  right: -72px;
  margin: auto 0;
}
.connect-3 {
  position: absolute;
  top: 0;
  bottom: -175px;
  right: -80px;
  margin: auto 0;
}
.connect-4 {
  position: absolute;
  top: -260px;
  bottom: 0;
  right: -48px;
  margin: auto 0;
}
.connect-5 {
  position: absolute;
  top: 0;
  bottom: -260px;
  right: -48px;
  margin: auto 0;
}

.connect-180 {
  position: absolute;
  top: 0;
  bottom: 0;
  right: -51px;
  margin: auto 0;
}
.connect-180-small {
  position: absolute;
  top: 0;
  bottom: 10px;
  right: -52px;
  margin: auto 0;
}

.box-img {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100px;
  text-align: center;
  border: 2px solid #bfbfbf;
  color: #bfbfbf;
  background-color: #fff;
  border-radius: 10px;
  font-size: 10px;
  padding: 10px;
  position: relative;
}
.box-img label {
  position: absolute;
  top: -40px;
  left: 0;
  right: 0;
  margin: auto 0;
  color: #4abceb;
  font-weight: 600;
}
.box-img-blue {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100px;
  text-align: center;
  color: #fff;
  background-color: #4abceb;
  border-radius: 10px;
  font-size: 10px;
  padding: 10px;
  position: relative;
}
.box-img-blue label, .box-img-2-blue label, .box-img-3-blue label {
  height: fit-content;
  position: absolute;
  top: 0;
  bottom: 10px;
  right: -70px;
  margin: auto 0;
  color: #4abceb;
  font-weight: 600;
}
.box-img-3-blue p {
  height: fit-content;
  position: absolute;
  bottom: -30px;
  left: 0;
  right: 0;
  margin: auto 0;
  color: #4abceb;
  font-weight: 600;
}

.box-img img {
  width: 32px;
  height: 32px;
}

.box-img-3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100px;
  min-height: 200px;
  text-align: center;
  border: 2px solid #bfbfbf;
  color: #bfbfbf;
  background-color: #fff;
  border-radius: 10px;
  font-size: 10px;
  padding: 10px;
  position: relative;
}
.box-img-3-blue {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100px;
  min-height: 200px;
  text-align: center;
  color: #fff;
  background-color: #4abceb;
  border-radius: 10px;
  font-size: 10px;
  padding: 10px;
  position: relative;
}

.box-img-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100px;
  min-height: 250px;
  text-align: center;
  border: 2px solid #bfbfbf;
  color: #bfbfbf;
  background-color: #fff;
  border-radius: 10px;
  font-size: 10px;
  padding-inline: 10px;
  position: relative;
}
.box-img-2-blue {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100px;
  min-height: 250px;
  text-align: center;
  color: #fff;
  background-color: #4abceb;
  border-radius: 10px;
  font-size: 10px;
  padding: 10px;
  position: relative;
}

.ani-grey-box {
  position: relative;
  margin-left: 50px;
  margin-bottom: 120px;
  max-width: 100px;
  background-color: #a6a6a6;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  font-size: 10px;
  text-align: center;
}
.ani-grey-box p {
  position: absolute;
  top: -150px;
  left: 0;
  right: 0;
  margin: auto 0;
  color: #4abceb;
  font-weight: 600;
}
.ani-grey-box ul {
  text-align: left;
}
.ani-grey-box li::marker {
  color: #fff;
}

.no-padding-inline {
  padding-inline: 0;
  justify-content: space-between;
}

.box-compartment {
  width: 100%;
  border-bottom: 2px solid #bfbfbf;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.box-img-2 .box-compartment:nth-child(4), .box-img-3 .box-compartment:nth-child(2) {
  border-bottom: none;
}

.text-group-1, .text-group-2, .text-group-3, .text-group-4, .text-group-5 {
  opacity: 0;
  animation: cycleItems 15s infinite;
}
.text-group-2 {
  animation-delay: 3s;
}
.text-group-3 {
  animation-delay: 6s;
}
.text-group-4 {
  animation-delay: 9s;
}
.text-group-5 {
  animation-delay: 12s;
}

@keyframes cycleItems {
  0% {
    opacity: 0;
  }
  25% {
      opacity: 1;
  }
  50% {
      opacity: 1;
  }
  75% {
      opacity: 1;
  }
  100% {
      opacity: 0;
  }
}

.yu-flex{
  display: flex;
}

.yu-mt{
  margin-top:24px;
}

.yu-mr,
.yu-mr-2{
  margin-right: 48px;
}

.yu-mr:first-of-type,
.yu-mr:nth-child(4) {
  margin-top: 200px;
}
.yu-mr:nth-of-type(2), 
.yu-mr:nth-of-type(5) {
  margin-top: 80px;
}

.yu-mr:last-of-type,
.yu-mr-2:last-of-type {
  margin-right: initial;
}

.yu-mr-2:nth-child(2),
.yu-mr-2:nth-child(3),
.yu-mr-2:nth-child(4){
  margin-top: 64px;

}
.yu-mr-2:nth-child(5) {
  margin-top: 277px;
}



.yu-relative {
  position: relative;
}

.yu-absolute {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
}

.yu-g-1,
.yu-g-2,
.yu-g-3 {
  /* align-items: center; */
  align-items: start;
  gap: 48px;
}

.yu-f-1{
  margin-top: 224px;
}
.yu-f-2{
  margin-top: 104px;
}
.yu-f-3{
  margin-top:24px;
}
.yu-f-4{
  margin-top: 224px;
}
.yu-f-5{
  margin-top: 104px;
}
.yu-f-6{
  margin-top: 344px;
}
.yu-f-7{
  margin-top: 264px;
}
.yu-f-8{
  margin-top: 512px;
}

.yu-mt-x{
  margin-top: 72px;
  margin-left: 45px;
}
.yu-mt-x-2{
  margin-top: 48px;
  margin-left: 45px;
}
.yu-mt-x-3{
  margin-top: 78px;
  margin-left: 96px;
}

.ske {
  padding: 15px 10px 12px 10px;
  border: 1px solid #c8d0db;
  width: 96px;
  height: 96px;
  border-radius: 8px;
  margin-top:24px;
}
.ske-a {
  padding: 15px 10px 12px 10px;
  border: 1px solid #c8d0db;
  width: 96px;
  height: 96px;
  border-radius: 8px;
}
.ske-a p{
  opacity: 0;
  text-align: center;
  font-size: 14px;
  line-height: 18px;
  margin-top: 8px;
  color: #0A2540;
}
.ske-a:hover{
  border: 0;
  background-color: white;
}
.ske-a:hover p{
  opacity: 1;
}

.ske img,
.ske-a img,
.ske-b img,
.ske-b > svg {
  display: flex;
  justify-content: center;
  margin: auto;
  margin-top: 15px;
}

.ske p {
  text-align: center;
  font-size: 12px;
  opacity: 0;
  line-height: 15px;
  margin-top: 20px;
  color: #0A2540;
}

.ske-b {
  padding: 15px 10px 12px 10px;
  width: 96px;
  height: 96px;
  border-radius: 8px;
  background-color: white;
}
.ske-b img{
  margin-top: initial;
}

.ske-b p {
  text-align: center;
  font-size: 14px;
  line-height: 18px;
  margin-top: 8px;
  color: #0A2540;
}

.yu-path{
  stroke-dasharray: 1;
  stroke-dashoffset: 1;
  animation: dash 3s linear forwards;
}

.yu-g-1:hover .yu-animate-1{
  transform: scale(0);
  opacity: 0;
  animation: scaleAnimation 3s ease forwards;
  animation: moveAlongPath 5s linear infinite;;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 5s linear forwards;

}

@keyframes scaleAnimation {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}

@keyframes moveAlongPath {
  0% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 1000;
  }
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

.yu-none{
  display: none;
}

.yu-gru-1:hover .yu-none{
  display: block;
}

.z-back{
  z-index: -1;
}

.mt24{
  margin-top: 24px;
}
.mt66{
  margin-top: 66px !important;
}
.mt67{
  margin-top: 67px !important;
}

.mt43{
  margin-top: 43px !important;
}
.mt50{
  margin-top: 50px !important;
}
.mt112{
  margin-top: 112px !important;
}
.ml45{
  margin-left: 45px;
}
.ml96{
  margin-left: 96px;
}
.mt186{
  margin-top: 186px;
}
.mt232{
  margin-top: 232px;
}
.mt371{
  margin-top: 371px;
}
.mt286{
  margin-top: 286px;
}
.mt240{
  margin-top: 240px;
}

.opacity-0{
  opacity: 0;
}

.ske-b p {
  font-size: 12px !important;
  line-height: 12px !important;
}