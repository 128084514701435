.distribution p {
  color: #425466;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}
.distribution li {
  color: #425466;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}
.distribution ul {
  padding-left: 15px;
}