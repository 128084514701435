.duo-app-1 {
    margin-right: 20px;
}
.duo-app-1-1 {
    margin-top: 50px !important;
}
.duo-sm-btn {
    display: flex;
    width: 100px;
    border: 1px solid #565656;
    border-radius: 50px;
    padding: 8px 10px;
}
.duo-sm-btn img {
    margin: 0;
}
.duo-sm-btn p {
    text-align: center;
    margin-bottom: 0;
    margin-left: 5px;
    font-size: 14px;
    font-weight: 400;
    font-family: Söhne;
}
.duo-app-h1 {
    margin-bottom: 40px;
}