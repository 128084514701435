.act-hero h1 {
    width: 600px;
    max-width: 100%;
}
.act-hero {
    padding: 70px 0 70px 200px;
}
.act-body-1 {
    height: 380px;
}
.act-img {
    margin-left: 150px;
}
.act-img img {
    
}
.act-body {
    padding: 100px 200px 0 200px;
}

.act-sm-btn {
    width: 100px;
    display: flex;
    border: 1px solid #565656;
    border-radius: 50px;
    padding: 8px 10px;
}
