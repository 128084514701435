.moneylink-footer1 p {
  color: #4d148c;
  font-family: Söhne;
  font-size: 38px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 455px;
}
.moneylink-footer1 p span {
  color: #ff5900;
  font-family: Söhne;
  font-size: 38px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.moneylink-footer1 button {
  border-radius: 8px;
  background: #ff5900;
  border: none;
  color: #fff;
  text-align: center;
  
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 145%;
  padding: 10px 15px;
}
