.sup-payment-hero {
  padding: 70px 100px 70px 200px;
}

.sup-payment-hero button {
  border-radius: 8px;
  background: #4abceb;
  border: none;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 145%;
  padding: 10px 15px;
}
.sup-payment-hero h1 {
  color: #20336b;
  font-family: Söhne2;
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
  line-height: 68px;
}
.sup-payment-hero h1 b {
  font-family: Söhne3;
}
.sup-payment-hero img {
  display: flex;
  justify-content: flex-end;
  margin: auto;
  margin-right: 0;
}
.sup-payment-hero h1 span {
  font-family: Söhne2;
  font-weight: 400;
}