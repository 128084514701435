.dlm-boxes {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
}

.dml-hero {
  padding: 70px 100px 70px 200px;
}

.dlm-sm-btn {
  width: fit-content !important;
}

.dlm-box {
  width: 250px;
  max-width: 100%;
  height: 350px;
  padding: 20px;
  border: 1px solid;
  border-radius: 10px;
}

.dlm-box p {
  font-size: 14px;
  font-family: Söhne;
  line-height: 24px;
  color: #0a2540;
}
.dlm-list {
  width: 500px;
  height: 800px !important;
  padding: 10px;
}
.dlm-img {
  margin-top: 350px;
}
.dlm-dlm-box {
  height: 630px !important;
}
.duo-ani-1 {
  display: flex;
  background: #ecf1f7;
  padding: 15px;
  border-radius: 10px;
}
.duo-ani-1 img {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.duo-ani {
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 237px;
}

.duo-ani-1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 90%;
  /* height: 100%; */
  display: flex;
  justify-content: space-between;
  transition: width 1s, opacity 1s;
}

.duo-ani-1 img {
  width: 30%;
  transition: opacity 1s;
}

.duo-ani-2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: #D6E2F1; /* Change the background color as needed */
  transition: width 1s;
  overflow: hidden;
  /* height: 191px; */
  margin-left: 49%;
}

.reduce-width {
  width: 50%;
  height: 100%;
}

.reduce-opacity {
  opacity: 0.5;
}

.increase-opacity {
  opacity: 1;
}

.expand-width {
  width: 50%;
}

.reduce-size {
  transform: scale(0.5);
}

.magnet {
    display: block !important;
    margin: 0% !important;
    margin-top: 80px !important;
}
.people1 {
    
    display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  gap: 0;
  margin-top: -100px;
    position: absolute;
    margin-left: 120px;
}
.people2 {
    display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  gap: 0;
  margin-top: -100px;
  margin-left: 154px;
    position: absolute;
}
.people1 img:nth-child(1) {
    grid-column: 1 / 3;
}

.people1 img {
    margin: 10px !important;
}
.people2 img {
    margin: 10px !important;
}

.magnet img,
.people1 img,
.people2 img {
  display: block;
  /* width: 100%; */
  /* opacity: 0; */
  transition: opacity 1s;
}

.magnet.show img {
  opacity: 1;
}

.people1.show img {
  opacity: 1;
  animation: moveLeft1 2s linear infinite;
}

.people2.show img {
  opacity: 1;
  animation: moveLeft2 2s linear infinite;
}

@keyframes moveLeft1 {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes moveLeft2 {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.magnet {
    transition: transform 0.5s ease;
    transform: translateX(-59%);
}

.magnet.slide-in {
    transform: translateX(0);
}