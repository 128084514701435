.col-hero-p {
    color: #425466;
    font-size: 18px;
    margin-top: 20px;
}
.collection-hero-1 {
    width: 50%;
}
.duo-lists {
    width: 566px !important;
}