.dwp-sm-btn {
    width: 150px;
    display: flex;
    border: 1px solid #565656;
    border-radius: 50px;
    padding: 8px 10px;
}

.dwp-sm-btn img {
    margin: 0;
}
.dwp-sm-btn p {
    text-align: center;
    margin-bottom: 0;
    margin-left: 5px;
    font-size: 14px;
    font-weight: 400;
    font-family: Söhne;
}
.dwp-hero {
    padding: 70px 0 70px 200px;
}
.dwp-body {
    padding: 70px 100px 70px 200px;
}
.dwp-app-1 {
    width: 500px;
    height: 600px;
}

.dwp-row {
    display: flex;
    justify-content: space-between;
}

.dwp-img img {
    position: absolute;
    margin-top: 130px;
    width: 35%;
    margin-left: 130px;

}
.dwp-h1-h1 {
    font-family: Söhne3 !important;
    margin-bottom: 30px;
}