.imp-hero {
  padding: 70px 200px;
  display: flex;
  align-items: flex-start;
  gap: 4rem;
}

.hero-text {
  color: #101010;
  font-family: Söhne;
  font-size: 56px;
  font-style: normal;
  font-weight: 500;
  line-height: 68px;
}
.hero-text b {
  font-family: Söhne3;
}

.imp-hero button {
  padding: 8px 15px;
  border: none;
  border-radius: 8px;
  background: #ffc000;
  color: #101010;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  margin-top: 30px;
  line-height: 145%; /* 23.2px */
}

.imp-body {
  padding: 70px 200px;
  background-color: #F6F9FC;
  padding-block: 8rem;
  text-align: center;
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 4.5rem;
  row-gap: 3rem;
  margin-block-start: 4rem;
}

.grid-item {
  display: flex;
  align-items: flex-start;
  gap: 0.75rem;
  text-align: left;
}
.grid-item h5 {
  font-weight: 600;
}
.grid-item p {
  color: #425466;
}