.lms-hero {
  padding: 70px 100px 70px 200px;
}
.lms-hero h1 {
  color: #4d148c;
  font-family: Söhne;
  font-size: 56px;
  font-style: normal;
  font-weight: 300;
  line-height: 68px;
}
.lms-hero h1 span {
  color: #4d148c;
  font-family: Söhne;
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
  line-height: 68px;
}
.lms-hero-2 img {
  display: flex;
  justify-content: end;
  margin: auto;
  margin-right: 0;
}
.lms-hero p {
  color: #4d148c;
  font-family: Söhne;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding: 10px 0;
}

.lms-hero button {
  border-radius: 8px;
  background: #ff5900;
  color: #fff;
  text-align: center;
  font-feature-settings: "cv04" on, "cv03" on, "cv01" on;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 145%;
  padding: 10px 15px;
  border: none;
  margin-top: 30px;
}

.lm1 {
  display: flex;
  justify-content: space-between;
  padding: 70px 200px;
}
.lmm {
  padding: 20px;
  border: 1px solid #4D148C;
  background: #F8F6FC;
  height: 350px;
  width: 300px;
  margin: 0 10px;
  border-radius: 10px;
}

.lmm h6 {
  font-size: 18px;
  font-weight: 600;
  color: #4D148C;
}

.lmm p {
  font-size: 16px;
  color: #4D148C;
}