.story-hero img {
    width: 100%;
}
.story-hero {
    padding-top: 10px;
    position: relative;
}
.our-story-map img {
    display: flex;
    justify-content: center;
    margin: auto;
}
.our-story-map {
    margin-top: 100px;
}
.our-story-map h1 {
    text-align: center;
    font-size: 38px;
    padding-bottom: 30px;
    font-family: Söhne3;
    font-weight: 500;
}
/* .year-btns {
    display: flex;
    background: #fff;
    padding: 20px;
    justify-content: center;
    margin: auto;
    margin-top: -50px;
    position: relative;
    z-index: 999;
    width: 558px;
    border-radius: 52px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
 
}
.year-button {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 1px solid #D0D5DD;
    background: transparent;
    margin: 0 70px 0 0;
}




  .year-btn::after {
    content: '';
    position: absolute;
    top: 16%;
    right: 0;
    transform: translateY(-50%);
    height: 1px;
    background-color: #D0D5DD;
    width: 70px;
  }
  .year-btn {
    position: relative;
  }
  .year-btn:last-child::after {
    display: none;
  }
  .year-btn p {
    font-size: 14px;
    text-align: left;
  } */

  .year-btns {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background: #fff;
    padding: 20px;
    border-radius: 52px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 558px;
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -50px;
  }
  .year {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
  }
  .connect-group {
    display: flex;
    align-items: center;
  }
  .year-btns > .year:first-child .connect-group {
    padding-inline-start: 10px;
  }
  .year-btns > .year:last-child .connector {
    display: none;
  }
  .year label {
    margin-left: -10px;
  }
  .year-btns > .year:first-child label {
    margin-left: 0;
  }

  .connector {
    width: 70px;
    height: 1px;
    background-color: #D0D5DD;
  }

  /* .year-radio {
    width: 20px;
    height: 20px;
    background-color: #fff;
    accent-color: #00BAF2;
  } */

  .year-radio {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    position: relative;
    right: 0;
    bottom: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background: #fff;
    border: 1.25px solid #D0D5DD;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    outline: none;
    z-index: 1000;
    border-radius: 50%;
  }
  .year-radio:checked {
    background-color: #00BAF2;
    border: none;
  }
  .year-radio:checked::before {
    width: 20px;
    height: 20px;
    display: flex;
    content: url('../../../assets/images/tick.svg');
    font-size: 25px;
    font-weight: bold;
    position: absolute;
    bottom: 5px;
    align-items: center;
    justify-content: center;
  }
  .year-radio:checked::after {
    background: #00BAF2;
    content: '';
    display: block;
    position: relative;
    z-index: 100;
  }

  .story-map {
    position: relative;
  }

  .story-map img:nth-child(2) {
    position: absolute;
    bottom: 120px;
    left: 32%;
  }