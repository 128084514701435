.business-agency {
  padding: 50px 200px 400px 200px;
}

.business-agency h1 {
  color: #20336b;
  font-family: Söhne2;
  font-size: 56px;
  font-style: normal;
  font-weight: 300;
  line-height: 68px;
  width: 602px;
}

.business-agency p {
  color: #20336b;
  font-family: Söhne;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  padding: 10px 0;
}

.business-agency h1 span {
  color: #20336b;
  font-family: Söhne;
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
  line-height: 68px;
}
.business-agency-2 {
  display: flex;
  justify-content: end;
}

.ab {
  position: absolute;
  left: 64%;
  top: 121%;
  z-index: 1;
}
