.sol-nav-link .active {
  color: #ffc000 !important;
  font-weight: bold;
}

.sol-button {
  background: #ffc000 !important;
  color: #101010 !important;
  font-weight: 600 !important;
  font-family: Inter !important;
}
.duo-overview-hero {
  padding: 50px 100px 150px 200px;
}
.duo-overview-hero h1 {
  color: #101010;
  font-family: Söhne2;
  font-size: 56px;
  font-style: normal;
  font-weight: 300;
  line-height: 68px;
}
.duo-overview-hero h1 span {
  color: #101010;
  font-family: Söhne;
  font-size: 56px;
  font-style: normal;
  font-weight: 500;
  line-height: 68px;
}
.duoheroimg {
  display: flex;
  justify-content: end;
  margin: auto;
  margin-right: 0;
}
.duo-overview-hero p {
  color: #425466;
  font-family: Söhne;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  padding: 10px 0;
}
.duohero2 {
  display: block;
}
.duohero2 img {
  width: 80%;
}
.duohero2 button {
  margin-top: 20px;
  border: none;
  border-radius: 8px;
  background: #ffc000;
  color: #101010;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 145%;
  padding: 10px 15px;
}
