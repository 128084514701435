.hero {
  padding-inline-start: 200px;
  padding-block-start: 70px;
  padding-block-end: 400px;
  display: flex;
  gap: 208px;
  align-items: flex-start;
}

.mobile-animation {
  position: relative;
  width: 100%;
  height: 100%;
}

.mobile-animation img {
  position: absolute;
  opacity: 0;
  animation: fade 9s infinite;
}

.mobile-animation img:nth-child(1) {
  animation-delay: 0s;
}
.mobile-animation img:nth-child(2) {
  animation-delay: 3s;
}
.mobile-animation img:nth-child(3) {
  animation-delay: 6s;
}

@media screen and (max-width: 1024px) {
  .mobile-animation {
    display: none;
  }
  .hero {
    padding-inline-start: 20px;
    padding-inline-end: 20px;
  }
  .pricing-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    border-radius: 16px;
    gap: 68px;
    margin-inline: 20px;
    width: 100% !important;
  }
  .pricing-container ul {
    text-align: left;
  }
}

.tap-pay-main h1 {
  color: #20336b;
  font-family: Söhne2;
  font-size: 56px;
  font-style: normal;
  font-weight: 300;
  line-height: 68px;
}
.tap-pay-main b {
  font-weight: 600;
}

.tap-pay-main  {
  color: #20336b;
  font-family: Söhne;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  width: 500px;
  padding: 10px 0;
}

.body-main {
  position: relative;
  z-index: 1000;
  background-color: #20336b;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 100px 0;
}
.body-main h2 {
  font-size: 38px;
  font-weight: 500;
  color: #4ABCEB;
  text-align: center;
}
.body-main p {
  color: #C5CFD8;
}
.gap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}

.grids {
  margin-block-end: 8rem;
  justify-content: center;
}
.grids img {
  height: 36px;
}

.pricing {
  background-color: #fff;
  padding-block: 8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.pricing h2 {
  color: #0A2540;
  text-align: center;
}

.tags {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.tag {
  border-radius: 8px;
  background-color: #DEE6FD;
  color:#20336B;
  padding-block: 12px;
  padding-inline: 16px;
  font-size: 14px;
  text-transform: uppercase;
}

.pricing-container {
  position: relative;
  width: 837px;
  background-color: #20336B;
  padding-block: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 16px;
  gap: 68px;
}
.pricing-container h3 {
  font-size: 40px;
  font-weight: 600;
}
.pricing-container .icon {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  left: -30px;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: #fff;
  background-image: url('../../../assets/images/features-5.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 23px 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #4ABCEB;
}

.pricing-container ul {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-inline-start: 40px;
  border-inline-start: 1px solid #fff;
}
.pricing-container li {
  list-style-position: inside;
  list-style-image: url('../../../assets/images/check-2.svg');
  font-size: 18px;
}

/* Animations */
@keyframes fade {
  0% {
    opacity: 0;
  }
  33.33% {
    opacity: 1;
  }
  66.66% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
