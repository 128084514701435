.main p {
  color: #425466;
  font-size: 16px;
  font-weight: 400;
}

.icon-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100px;
  gap: 8px;
  text-align: center;
}

.icon-wrapper {
  display: flex;
  background-color: #20336B;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
}
.icon-container label {
  font-size: 16px;
  font-weight: 400;
}

.gateway {
  padding-block: 70px;
  padding-inline-start: 200px;
  display: flex;
  align-items: flex-start;
  gap: 7rem;
  color: #20336B;
}
.gateway .diagram {
  display: block;
}

@media screen and (max-width: 1024px){
  .gateway {
    padding-inline-start: 20px;
    padding-inline-end: 20px;
    padding-block: 30px;
  }
  .gateway .diagram {
    max-width: 100%;
  }
  .gateway > img {
    display: none;
  }
  .info-1, .info-2, .info-3, .info-4, .info-5 {
    display: none !important;
  }
  .prices {
    flex-direction: column;
    max-height: none !important;
  }
  .price-item {
    min-width: fit-content !important;
    height: 435px !important;
  }
}

.gateway p {
  font-size: 18px;
  line-height: 28px;
}

.cta {
  margin-block-start: 2rem;
  padding: 16px 24px;
  font-weight: 600;
  color: #fff;
  background-color: #4ABCEB;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

.title {
  font-size: 56px;
  font-weight: 300;
}
.title b {
  font-weight: 700;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: 18px;
}
.list li::marker {
  background-color: #20336B;
}

.body {
  background-color: #20336B;
  padding-block: 8rem;
  padding-inline: 200px;
  color: #fff;
}

.body h2 {
  text-align: center;
  font-size: 38px;
  margin-block-end: 4rem;
}

.checkout-steps {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  align-items: flex-start;
  gap: 25px;
}

.card {
  position: relative;
  background-color: #fff;
  padding-inline: 20px;
  padding-block: 1.5rem;
  line-height: 24px;
  color: #425466;
  border-radius: 16px;
  border: none;
}
.card img {
  margin-left: auto;
  margin-right: auto;
}
.card h5 {
  font-weight: 500;
  font-size: 18px;
  color: #0A2540;
  margin-block-start: 1rem;
}
.card ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  margin-block-start: 20px;
  color: #0A2540;
  padding: 0;
  font-size: 14px;
}
.card li {
  display: flex;
  gap: 12px;
  align-items: flex-start;
}

.triangle {
  position: absolute;
  top: 60px;
  right: -16px;
  width: 0;
	height: 0;
	border-top: 8px solid transparent;
	border-left: 16px solid #fff;
	border-bottom: 8px solid transparent;
}
.cutout {
  position: absolute;
  top: 60px;
  left: 0;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
	border-left: 16px solid #20336b;
	border-bottom: 8px solid transparent;
}
.one {
  top: 60px;
}
.two {
  top: 70px;
}
.three {
  top: 50px;
}
.four {
  top: 60px;
}

.circle {
  width: 2rem;
  height: 2rem;
  background-color: #3FAFE9;
  border-radius: 50%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.info-1 {
  position: absolute;
  display: flex;
  align-items: center;
  bottom: 100px;
  left: -500px;
}
.info-2 {
  position: absolute;
  display: flex;
  align-items: center;
  bottom: 30px;
  left: -400px;
}
.info-3 {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  bottom: -100px;
  left: 10px;
}
.info-4 {
  position: absolute;
  display: flex;
  align-items: center;
  right: -220px;
  bottom: 100px;
}
.info-5 {
  position: absolute;
  display: flex;
  align-items: center;
  right: -220px;
  bottom: 100px;
}
.info-3 .info-body {
  height: fit-content !important;
  max-width: 173px;
}
.info-4 .info-body {
  height: fit-content !important;
  max-width: 189px;
}

.info-body {
  color: #fff;
  font-size: 14px;
  line-height: 18px;
  padding-block: 8px;
  padding-inline: 12px;
  border: 1px solid #fff;
  border-radius: 8px;
}

.info-line {
  height: 1px;
  width: 30px;
  background-color: #fff;
}
.info-line-vertical:first-of-type {
  height: 30px;
  width: 1px;
  background-color: #fff;
  margin-right: 120px;
}

.sdk {
  padding-inline: 200px;
  padding-block: 8rem;
  text-align: center;
  color: #0A2540;
}

.sdk-wrapper {
  display: flex;
  gap: 2.5rem;
  justify-content: center;
  align-items: center;
  margin-block: 4rem;
}

.sdk-card {
  box-shadow: 0px 0px 0px 1px #1019280D;
box-shadow: 0px 0px 3px -1px #1019280A;
box-shadow: 0px 16px 52px -6px #1019282E;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: 1rem;
  width: 240px;
  padding-block: 1rem;
}
.sdk-card img {
  height: 200px;
  object-fit: contain;
}

.tables {
  background-color: #F6F9FC;
  padding-inline: 200px;
  padding-block: 8rem;
}
.tables h3 {
  text-align: center;
  color: #0A2540;
}
.tables h3:first-child {
  color: #1BAFEB;
}

.tables .grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: flex-start;
  column-gap: 72px;
  row-gap: 48px;
  text-align: left;
}
.table-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: flex-start;
  column-gap: 36px;
  row-gap: 32px;
}

.no-margin {
  margin-block-start: 0 !important;
  margin-block-end: 0 !important;
  margin-inline-start: 0 !important;
  margin-inline-end: 0 !important;
}

.description {
  display: flex;
  gap: 12px;
  align-items: flex-start;
}
.description h5 {
  font-size: 18px;
  font-weight: 600;
}

.description-col {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.description-col .grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 72px;
  row-gap: 48px;
}
.description-col h5 {
  font-size: 18px;
  font-weight: 600;
}
.description-col h5 {
  font-size: 18px;
  font-weight: 600;
}
.description-col .icon {
  width: 36px;
  height: 36px;
}
.blue-circle {
  background-color: #20336B;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #425466;
}
.blue-circle h5 {
  color: #0A2540;
}
.blue-circle img {
  object-position: center;
  object-fit: contain;
}

.compare-table {
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-align: left;
  border: 1px solid #C5D1D5;
  border-radius: 16px;
}
.compare-table .item {
  width: 100%;
  padding: 24px;
  margin: 0;
}
.compare-table .grid {
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 36px;
  row-gap: 56px;
}

.pricing {
  padding-inline: 200px;
  padding-block: 8rem;
  text-align: center;
  color: #0A2540;
}
.pricing h2 {
  font-size: 38px;
  font-weight: 500;
}

.tags {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.tag {
  border-radius: 8px;
  background-color: #DEE6FD;
  color:#20336B;
  padding-block: 12px;
  padding-inline: 16px;
  font-size: 14px;
  text-transform: uppercase;
}
.features {
  margin-block-start: 8rem;
}
.features h3 {
  color: #00ACE0;
}
.payment h3 {
  font-size: 38px;
  color: #0A2540 !important;
  text-align: center;
  margin: 0;
}
.payment .description {
  max-width: 800px;
  font-size: 18px;
  color: #425466;
  text-align: center;
}

.payment-step-imgs {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 83px;
}

.payment-main {
  display: flex;
  align-items: flex-start;
}

.text-group {
  text-align: left;
  flex: 1;
}
.text-group .text {
  padding-inline-end: 25px;
}

.connector-group {
  width: 100%;
  display: flex;
  align-items: center;
}
.connector {
  display: flex;
  align-items: center;
}

.dot {
  background-color: #00ACE0;
  color: #fff;
  font-size: 10px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.connect-line {
  height: 1px;
  width: 100%;
  background-color: #D8D8D8;
}

.prices {
  width: 100%;
  max-height: 645px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-block: 4rem;
}
.price-item {
  position: relative;
  padding-inline: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  color: #fff;
  padding-block: 24px;
  max-width: 208px;
}
.price-item:nth-of-type(1) {
  background-color: #5E7CD0;
  height: 374px;
}
.price-item:nth-of-type(2) {
  background-color: #0B74E6;
  height: 435px;
}
.price-item:nth-of-type(3) {
  background-color: #B3C6E7;
  color: #20336B;
  height: 500px;
}
.price-item:nth-of-type(4) {
  background-color: #00ACE0;
  height: 557px;
}
.price-item:nth-of-type(5) {
  background-color: #20336B;
  height: 620px;
}
.price-item hr {
  width: 100%;
  border: 1px solid #fff;
  margin-block: 1rem;
}
.price-item ul {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  gap: 8px;
}
.price-item li {
  list-style-position: outside;
  list-style-image: url('../../../assets/images/check.svg');
}
.price-icon {
  position: absolute;
  background-color: #fff;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -24px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  color: #fff;
}
.price-item:nth-of-type(1) .price-icon {
  border: 1px solid #5E7CD0;
}
.price-item:nth-of-type(2) .price-icon {
  border: 1px solid #0B74E6;
}
.price-item:nth-of-type(3) .price-icon {
  border: 1px solid #B3C6E7;
}
.price-item:nth-of-type(4) .price-icon {
  border: 1px solid #00ACE0;
}
.price-item:nth-of-type(5) .price-icon {
  border: 1px solid #20336B;
}

.center {
  margin-left: auto;
  margin-right: auto;
}

.margin-bottom {
  margin-block-end: 8rem;
}

.paid-instantly {
  padding: 0;
}

@media screen and (max-width: 768px){
  .checkout-steps {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 1024px){
  .body, .sdk, .tables, .pricing {
    padding-inline: 20px;
  }

  .card .info, .card .line {
    display: none;
  }

  .sdk-wrapper {
    flex-direction: column;
  }

  .tables .grid {
    grid-template-columns: repeat(1, 1fr);
  }

  .tables .payment-table-1 {
    display: none;
  }
}