.gap-92{
    gap: 92px;
}
.gap-72{
    gap: 72px;
}

.yu-mr-3:nth-child(1) {
  margin-top: 208px;
}

.yu-mr-4:nth-child(1),
.yu-mr-4:nth-child(3){
    margin-top: 52px;
}

.yu-mr-4:nth-child(4){
    margin-top: 330px;
}

.mt8{
    margin-top: 8px !important;
}
.mt20{
    margin-top: 20px !important;
}

.mt48 {
  margin-top: 48px !important;
}

.mt120{
  margin-top: 120px !important;
}

.mt192{
  margin-top: 192px !important;
}

.mt216{
  margin-top: 216px !important;
}


.mt190{
    margin-top: 190px !important;
}

.ml48{
    margin-left: 48px !important;
}

.mt325{
    margin-top: 325px !important;
}

.mt468{
    margin-top: 455px !important;
}
.mt504{
    margin-top: 504px !important;
}

.mt72{
    margin-top: 72px !important;
}

.mt124{
    margin-top: 124px !important;
}
.mt215{
    margin-top: 215px !important;
}
.mt168{
    margin-top: 168px !important;
}
.mt220{
    margin-top: 220px !important;
}
.mt116{
    margin-top: 116px !important;
}

.ml96{
    margin-left: 96px !important;
}

.mt336{
    margin-top: 336px !important;
}

.mt378{
    margin-top: 378px !important;
}
.mt336{
    margin-top: 336px !important;
}
.mt556{
    margin-top: 556px !important;
}
.mt424{
    margin-top: 424px !important;
}

.z-1{
    z-index: 1;
}

.yu-flex-c{
    display: flex;
    flex-direction: column;
}

.gap-16{
    gap: 16px;
}

.nowrap{
    flex-wrap: nowrap;
    overflow-x: hidden;
}

.business-2 {
    width: 50%;
}

.ske img {
    position: static !important;
}