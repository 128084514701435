.dts-hero {
    padding: 70px 100px 70px 200px;
}

.dcp-body {
    padding: 70px 200px;
    background: #F6F9FC;   
}

.dcp-frame-1 {
    height: 430px;
}
.dcp-frame-2 {
    height: 430px;
    margin-left: 30px;
}