.mobile-menu-header {
    display: flex;
    justify-content: space-between;
}
.mobile-menu-header button {
    background: none;
    border: none;

}
.mobile-menu-header button i {
    font-size: 20px;
    font-weight: 700;
}
.mobile-menu  {
    height: 600px;
}

.mobile-menu-first {
   flex-direction: column;
   margin-top: 30px;
}
.mobile-menu-first a{
    text-decoration: none;
    color: #3f4b66;
    padding: 16px;
    font-size: 18px;
    font-weight: 500;
    font-family: Söhne !important;
    
}
.mobile-menu-first div {
    margin-top: 30px;
}

.menu-list {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-block-start: 1rem;
}
.menu-list li::marker {
    content: "—";
}