.debt-hero {
  padding: 70px 0px 70px 200px;
}

@media (max-width: 1024px) {
  .debt-hero {
    padding: 70px 0px 70px 20px;
  }

  .why-moneylink {
    padding: 30px 20px;
  }
}
.debt-hero h1 {
  color: #4d148c;
  font-family: Söhne;
  font-size: 56px;
  font-style: normal;
  font-weight: 300;
  line-height: 68px;
}
.debt-hero h1 span {
  color: #4d148c;
  font-family: Söhne;
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
  line-height: 68px;
}
.debt-hero-2 img {
  display: flex;
  justify-content: end;
  margin: auto;
  margin-right: 0;
}
.debt-hero p {
  color: #4d148c;
  font-family: Söhne;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding: 10px 0;
}

.debt-hero button {
  border-radius: 8px;
  background: #ff5900;
  color: #fff;
  text-align: center;
  font-feature-settings: "cv04" on, "cv03" on, "cv01" on;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 145%;
  padding: 10px 15px;
  border: none;
  margin-top: 30px;
}

.lm1 {
  display: flex;
  justify-content: space-between;
  padding: 70px 200px;
}
.lmm {
  padding: 20px;
  border: 1px solid #4D148C;
  background: #F8F6FC;
  height: 350px;
  width: 300px;
  margin: 0 10px;
  border-radius: 10px;
}

.lmm h6 {
  font-size: 18px;
  font-weight: 600;
  color: #4D148C;
}

.lmm p {
  font-size: 16px;
  color: #4D148C;
}

.cip-hero-card {
  max-width: 520px;
  height: auto;
}

.orange-circle {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #FF5900;
  border-radius: 50%;
}
.purple-circle {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #4D148C;
  border-radius: 50%;
}

.card-types {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 24px;
  margin-top: 62px;
}

.card-type ul {
  list-style-type: square;
  display: flex;
  flex-direction: column;
  gap: 9px;
}

.card-type li, li::marker {
  color: #4D148C;
}

@media screen and (max-width: 1024px) {
  .card-types {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 24px;
  }
  .card-type {
    margin-bottom: 0px !important;
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .card-types {
    grid-template-columns: repeat(1, 1fr);
    column-gap: 24px;
  }
}